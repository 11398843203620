import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Button, Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const AFKIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page afk-journey '} game="afk">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - AFK Journey wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for AFK Journey. Check our guides, tier lists and
          reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Play on PC" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Play AFK Journey on PC</h4>
          <OutboundLink
            href="https://www.ldplayer.net/games/com-farlightgames-igame-gp-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
            target="_blank"
          >
            <Button variant="primary">Play now on LDPlayer</Button>
          </OutboundLink>
        </div>
      </div>
      <SectionHeader title="Active codes" />
      <p>Check all active codes here:</p>
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Promo codes"
          link="/afk-journey/codes"
          image={
            <StaticImage
              src="../../images/afk/categories/category_codes.jpg"
              alt="Promo codes"
            />
          }
        />
      </Row>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Season 2 content" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Artifacts - Season 2"
          link="/afk-journey/guides/artifacts-season"
          image={
            <StaticImage
              src="../../images/afk/categories/category_arti_s.jpg"
              alt="Artifacts - Season 2"
            />
          }
          isNew
        />
        <CategoryCard
          title="Charms - Season 2"
          link="/afk-journey/guides/charms-season"
          image={
            <StaticImage
              src="../../images/afk/categories/category_charms.webp"
              alt="Charms - Season 2"
            />
          }
          isNew
        />
        <CategoryCard
          title="Talents"
          link="/afk-journey/guides/talents"
          image={
            <StaticImage
              src="../../images/afk/categories/category_talents.jpg"
              alt="Talents"
            />
          }
          isNew
        />
      </Row>
      <SectionHeader title="Dream Realm guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Crystal Beetle"
          link="/afk-journey/guides/dream-realm-crystal-beetle"
          image={
            <StaticImage
              src="../../images/afk/categories/category_crystal.webp"
              alt="Crystal Beetle"
            />
          }
          isNew
        />
        <CategoryCard
          title="Orson"
          link="/afk-journey/guides/dream-realm-orson"
          image={
            <StaticImage
              src="../../images/afk/categories/category_orson.webp"
              alt="Orson"
            />
          }
          isNew
        />
        <CategoryCard
          title="Lone Gaze"
          link="/afk-journey/guides/dream-realm-lone-gaze"
          image={
            <StaticImage
              src="../../images/afk/categories/category_lone.jpg"
              alt="Primal Lord - Lone Gaze"
            />
          }
        />
        <CategoryCard
          title="Alpha Bear"
          link="/afk-journey/guides/dream-realm-alpha-bear"
          image={
            <StaticImage
              src="../../images/afk/categories/category_bear.jpg"
              alt="Primal Lord - Alpha Bear"
            />
          }
        />
        <CategoryCard
          title="King Croaker"
          link="/afk-journey/guides/dream-realm-croaker"
          image={
            <StaticImage
              src="../../images/afk/categories/category_croaker.jpg"
              alt="King Croaker"
            />
          }
        />
        <CategoryCard
          title="Necrodrakon"
          link="/afk-journey/guides/dream-realm-necrodrakon"
          image={
            <StaticImage
              src="../../images/afk/categories/category_nekro.jpg"
              alt="Necrodrakon"
            />
          }
        />
        <CategoryCard
          title="Skyclops"
          link="/afk-journey/guides/dream-realm-skyclops"
          image={
            <StaticImage
              src="../../images/afk/categories/category_skyclops.jpg"
              alt="Skyclops"
            />
          }
        />
        <CategoryCard
          title="Snow Stomper"
          link="/afk-journey/guides/dream-realm-snow-stomper"
          image={
            <StaticImage
              src="../../images/afk/categories/category_yeti.jpg"
              alt="Snow Stomper"
            />
          }
        />
      </Row>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Frequently Asked Questions"
          link="/afk-journey/guides/frequently-asked-questions"
          image={
            <StaticImage
              src="../../images/afk/categories/category_faq.jpg"
              alt="Frequently Asked Questions"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/afk-journey/guides/reroll"
          image={
            <StaticImage
              src="../../images/afk/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner guide"
          link="/afk-journey/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/afk/categories/category_beginner.jpg"
              alt="Beginner guide"
            />
          }
        />
        <CategoryCard
          title="Tips and Tricks"
          link="/afk-journey/guides/tips-and-tricks"
          image={
            <StaticImage
              src="../../images/afk/categories/category_tips.jpg"
              alt="Tips and Tricks"
            />
          }
        />
        <CategoryCard
          title="Wishlist tips"
          link="/afk-journey/guides/wishlist-tips"
          image={
            <StaticImage
              src="../../images/afk/categories/category_wishlist.jpg"
              alt="Wishlist tips"
            />
          }
          updated
        />
        <CategoryCard
          title="Characters list"
          link="/afk-journey/characters"
          image={
            <StaticImage
              src="../../images/afk/categories/category_characters.jpg"
              alt="Characters list"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/afk-journey/tier-list"
          image={
            <StaticImage
              src="../../images/afk/categories/category_tier.jpg"
              alt="Tier List"
            />
          }
        />
        <CategoryCard
          title="Team building (beginner)"
          link="/afk-journey/guides/team-building"
          image={
            <StaticImage
              src="../../images/afk/categories/category_team.jpg"
              alt="Team building"
            />
          }
        />
        <CategoryCard
          title="Team building (advanced)"
          link="/afk-journey/guides/team-building-advanced"
          image={
            <StaticImage
              src="../../images/afk/categories/category_meta.jpg"
              alt="Team building"
            />
          }
        />
        <CategoryCard
          title="Exclusive Weapon priority"
          link="/afk-journey/guides/exclusive-weapon-priority"
          image={
            <StaticImage
              src="../../images/afk/categories/category_ex.jpg"
              alt="Exclusive Weapon priority"
            />
          }
        />
        <CategoryCard
          title="Shop (Emporium) guide"
          link="/afk-journey/guides/shop-guide"
          image={
            <StaticImage
              src="../../images/afk/categories/category_shops.jpg"
              alt="Shop (Emporium) guide"
            />
          }
        />
        <CategoryCard
          title="Spending guide"
          link="/afk-journey/guides/spending-guide"
          image={
            <StaticImage
              src="../../images/afk/categories/category_spending.jpg"
              alt="Spending guide"
            />
          }
        />
        <CategoryCard
          title="Battle Drills (Season)"
          link="https://www.afkanalytica.com/journey/battle-drill/song-of-strife"
          image={
            <StaticImage
              src="../../images/afk/categories/category_battles.jpg"
              alt="Guilds - Battle Drills"
            />
          }
          outsideLink
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default AFKIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="AFK Journey Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for AFK Journey. Check our guides, tier lists and reviews for characters available in the game."
    game="afk"
  />
);
